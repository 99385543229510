import { styled } from "@mui/material"
import { useSnackbar } from "notistack"

export const useCustomSnack = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showSnack = (
    message: string,
    variant: "success" | "error" | "warning" | "info" | undefined
  ) => {
    enqueueSnackbar(<Container dangerouslySetInnerHTML={{ __html: message }} />, {
      variant: variant,
    })
  }

  return { showSnack }
}

const Container = styled("div")`
  width: 100%;
  max-width: 400px;
  a {
    color: ${(props) => props.theme.palette.text.primary};
  }
`
