// Generate code challenge and verifier. Inspiration: https://stackoverflow.com/questions/63309409/creating-a-code-verifier-and-challenge-for-pkce-auth-on-spotify-api-in-reactjs
export async function generateCodeChallenge() {
  const convertNumberToHex = (dec: number) => ("0" + dec.toString(16)).substr(-2);
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  var codeVerifier = Array.from(array, convertNumberToHex).join("");
  var codeChallenge = base64urlencode(await sha256(codeVerifier));

  return { codeVerifier, codeChallenge };
}
function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}
function base64urlencode(a: ArrayBuffer) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}
