import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0a77ed', //blue
    },
    secondary: {
      main: '#103c23', // dark green
      light: "#d4f5e8" // light green
    },
    background: {
      paper: '#fff', // white
    },
    error: {
      main: '#f44336', // red
    },
    warning: {
      main: '#ffdb38', // yellow
    },
    success: {
      main: '#26d187', // green
    }
  },
  typography: {
    fontFamily: "Verdana, sans-serif",

    body1: { // used for p tags
      fontSize: "0.8rem",
    },
    h1: {
      fontFamily: "Georgia, serif",
      fontSize: '2rem',
    },
    h2: {
      fontFamily: "Georgia, serif",
      fontSize: '1.5rem',
    },
    h3: {
      fontFamily: "Georgia, serif",
      fontSize: '1.2rem',
    },
    h4: {
      fontFamily: "Georgia, serif",
      fontSize: '1rem'
    },
    h5: {
      fontFamily: "Georgia, serif",
      fontSize: '1rem'
    },
    h6: {
      fontFamily: "Georgia, serif",
      fontSize: '1rem'
    }
  }
});

export default theme;
