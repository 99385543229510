import { Grid, styled, Typography } from "@mui/material"
import CustomInputDate from "../FormInputs/CustomInputDate"

interface Props {
  title: string
  fromDate?: string
  toDate?: string
  onFromDateChangeHandler?: (value?: string) => void
  onToDateChangeHandler?: (value?: string) => void
}

const TableTitleSection = ({ onFromDateChangeHandler, onToDateChangeHandler, ...props }: Props) => {
  return (
    <Grid container columnSpacing={{ xs: 1 }}>
      <TitleGridItem item xs={8}>
        <TitleGridItemText variant="h2">{props.title}</TitleGridItemText>
      </TitleGridItem>
      <Grid item xs={2}>
        {!!onFromDateChangeHandler && (
          <CustomInputDate
            label="Fra dato"
            name="date"
            onChangeHandler={(value) => onFromDateChangeHandler(value)}
            invalidText="Dato påkrævet"
            value={props.fromDate}
          />
        )}
      </Grid>

      <Grid item xs={2}>
        {!!onToDateChangeHandler && (
          <CustomInputDate
            label="Til dato"
            name="date"
            onChangeHandler={(value) => onToDateChangeHandler(value)}
            invalidText="Dato påkrævet"
            value={props.toDate}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default TableTitleSection

const TitleGridItem = styled(Grid)`
  display: flex;
`
const TitleGridItemText = styled(Typography)`
  align-self: center;
`
