import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteWork } from "src/api/mutations/deleteWork"
import { LoadingButton } from "@mui/lab"
import { useCustomSnack } from "src/hooks/useCustomSnack"

interface Props {
  workId: string
}

export default function DeleteWorkButton(props: Props) {
  const [open, setOpen] = React.useState(false)

  const deleteWorkMutation = useMutation(deleteWork)
  const queryClient = useQueryClient()
  const { showSnack } = useCustomSnack()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    deleteWorkMutation.mutate(props.workId, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getWork"])
        showSnack("Arbejdet blev slettet", "success")
        handleClose()
      },
      onError: (error) => {
        showSnack((error as Error).message, "error")
      },
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton size="small" onClick={handleClickOpen} data-testid="delete-work-item-toggle">
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} data-testid="delete-work-dialog">
        <DialogTitle>Er du sikker på at du vil slette arbejdet?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Arbejdet slettes fra systemet og vil ikke blive sendt til OpenNet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteWorkMutation.isLoading} variant="contained" onClick={handleClose}>
            Fortryd
          </Button>
          <LoadingButton
            data-testid="delete-work-item-confirm-button"
            loading={deleteWorkMutation.isLoading}
            variant="contained"
            color="error"
            onClick={handleConfirm}
          >
            Slet
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
