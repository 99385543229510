import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";
import { ApiError, WorkDto } from "../generated";
import { genericErrorMessage } from "../serviceNowLink";

export const updateWork = async (obj: { id: string, body: WorkDto }) => {
  try {
    var result = await createTeleTechnicianWorkApiClient().works.putApiWorks(obj.id,
      {
        serviceSubscriptionId: obj.body.serviceSubscriptionId,
        productId: obj.body.productId,
        dateOfDelivery: obj.body.dateOfDelivery,
        totalPrice: obj.body.totalPrice,
        priceVolume: obj.body.priceVolume,
        description: obj.body.description
      }
    );

    return {
      serviceSubscriptionId: result.serviceSubscriptionId,
      productId: result.productId,
      dateOfDelivery: result.dateOfDelivery,
      totalPrice: result.totalPrice,
      priceVolume: result.priceVolume,
      description: result.description
    } as WorkDto;
  } catch (err) {
    var error = err as ApiError;
    if ((error.body?.Message as string)?.includes("Call to TeleWorkService failed")) // TODO: fix det her
      throw new Error("Linjen er allerede uploadet til OpenNet og kan ikke editeres.")

    throw new Error(`Linjen kunne ikke opdateres. ${genericErrorMessage}`);
  }
};