import SearchIcon from "@mui/icons-material/Search"
import { IconButton, styled, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import React from "react"

const SearchBar = ({ onChangeHandler }: { onChangeHandler: (value: string) => void }) => {
  const [searchQuery, setSearchQuery] = React.useState("")

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    onChangeHandler(searchQuery)
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <StyledTextField
          placeholder="Søg"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" data-testid="search-query-btn">
                  <StyledSearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          data-testid="search-query-input"
        />
      </form>
    </Container>
  )
}

export default SearchBar

const StyledTextField = styled(TextField)`
  width: 33%;
  margin-bottom: 10rem;
`

const StyledSearchIcon = styled(SearchIcon)`
  cursor: pointer;
`

const Container = styled("div")`
  width: 100%;
  position: relative;
  text-align: right;
`
