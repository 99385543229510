import { CircularProgress, styled, TableCell, TableRow } from "@mui/material"

const TableSpinner = () => {
  return (
    <TableRow>
      <TableCell colSpan={5}>
        <StatusContainer>
          <CircularProgress color="primary" size={30} />
        </StatusContainer>
      </TableCell>
    </TableRow>
  )
}

export default TableSpinner

const StatusContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`
