import { Dialog, DialogContent, DialogContentText, DialogTitle, styled } from "@mui/material"
import { ResponseWorkDto } from "src/api/generated"
import WorkForm from "../WorkForm/WorkForm"

interface Props {
  workToEdit?: ResponseWorkDto
  open: boolean
  onClose: () => void
}

const EditWorkModal = (props: Props) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="md" keepMounted>
        <DialogTitle variant="h2">Redigering af eksisterende linje</DialogTitle>
        <DialogContent>
          <DialogContentText>
            En linje kan redigeres inden den uploades til OpenNet. <br />
          </DialogContentText>
          <WorkFormContainer>
            <WorkForm
              mode="edit"
              existingWork={props.workToEdit}
              onSuccess={props.onClose}
              onError={props.onClose}
            />
          </WorkFormContainer>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EditWorkModal

const WorkFormContainer = styled("div")`
  margin-top: ${(props) => props.theme.spacing(4)};
`
