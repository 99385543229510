import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { ProductDto } from "src/api/generated"
import { getProducts } from "src/api/queries/getProducts"

interface Props {
  infrastructureOwner?: string
  value?: ProductDto
  onChangeHandler: (value?: ProductDto) => void
  triggerRequiredError?: boolean
  disabled?: boolean
}

const ProductSelector = ({ onChangeHandler: _onChangeHandler, ...props }: Props) => {
  const productsQuery = useQuery<ProductDto[], Error>(["getProducts"], getProducts)

  const filteredProducts = React.useMemo(
    () =>
      (
        productsQuery.data?.filter(
          (product) => product.infrastructureOwnerName === props.infrastructureOwner
        ) || []
      ).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [productsQuery.data, props.infrastructureOwner]
  )

  const onChangeHandler = React.useCallback(
    (value: string) => {
      var matchingProduct = filteredProducts.find((product) => product.id === value)

      _onChangeHandler(matchingProduct)
    },
    [_onChangeHandler, filteredProducts]
  )

  // we trigger onChange manually because the Select component doesn't trigger onChange by itself when the value is set to undefined
  React.useEffect(() => {
    if (!props.infrastructureOwner) onChangeHandler("")
  }, [onChangeHandler, props.infrastructureOwner])

  const loading = productsQuery.isLoading
  const disabled =
    props.disabled === true ||
    !props.infrastructureOwner ||
    loading ||
    !productsQuery.isFetched ||
    !productsQuery.data
  const displayError = !props.value?.infrastructureOwnerName && props.triggerRequiredError

  return (
    <Container>
      <FormControl fullWidth disabled={disabled} error={displayError} required>
        <InputLabel id="product-selector-label">
          Produkt
          {loading && (
            <>
              {" "}
              <CircularProgress color="primary" size={15} />
            </>
          )}
        </InputLabel>
        <Select
          labelId="product-selector-label"
          data-testid="product-selector"
          value={!!props.infrastructureOwner && !!props.value ? props.value.id : ""}
          label="Produkt"
          onChange={(event) => onChangeHandler(event.target.value)}
        >
          {filteredProducts.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{displayError ? "Produkt påkrævet" : " "}</FormHelperText>
      </FormControl>
    </Container>
  )
}
export default ProductSelector

const Container = styled("div")`
  width: 100%;
`
