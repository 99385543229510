/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssemblyInfoDto } from '../models/AssemblyInfoDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AssemblyInfoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get assembly information for TeleTechnicianWorkBackend, TeleWorkService and SITeleConnectionService
     * @returns AssemblyInfoDto Success
     * @throws ApiError
     */
    public getApiAssemblyInfo(): CancelablePromise<Array<AssemblyInfoDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/AssemblyInfo',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
