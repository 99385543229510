/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductDto } from '../models/ProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all products
     * @returns ProductDto Success
     * @throws ApiError
     */
    public getApiProductsDefault(): CancelablePromise<Array<ProductDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Products/Default',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
