import { styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { addDays, format } from "date-fns"
import React from "react"
import { ErrorFileLineDto } from "src/api/generated"
import { getErrorFileLines } from "src/api/queries/getErrorFileLines"
import ErrorFileLineHandledToggle from "../Menus/ErrorFileLineHandledToggle"
import CustomTableRow from "./CustomTableRow"
import TableRowDetails from "./TableRowDetails"
import TableSpinner from "./TableSpinner"
import TableTitleSection from "./TableTitleSection"

const ErrorFileLineTable = () => {
  const [fromDate, setFromDate] = React.useState<string | undefined>(
    new Date(addDays(Date.now(), -7)).toISOString()
  )
  const [toDate, setToDate] = React.useState<string | undefined>(new Date().toISOString())
  const queryClient = useQueryClient()

  const errorLinesQuery = useQuery<ErrorFileLineDto[], Error>(
    ["getErrorFileLines", fromDate, toDate],
    () => getErrorFileLines(fromDate, toDate)
  )

  const onHandledToggleHandler = (errorLine: ErrorFileLineDto, handled: boolean) => {
    queryClient.setQueryData<ErrorFileLineDto[]>(
      ["getErrorFileLines", fromDate, toDate],
      (oldData) =>
        oldData!.map((cachedErrorLine) =>
          cachedErrorLine.id !== errorLine.id
            ? cachedErrorLine
            : {
                ...cachedErrorLine,
                handled: handled,
              }
        )
    )
  }

  return (
    <Container>
      <TableTitleSection
        title="Fejlliste"
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChangeHandler={setFromDate}
        onToDateChangeHandler={setToDate}
      />
      <StyledTable data-testid="error-table">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader></StyledTableCellHeader>
            <StyledTableCellHeader>Dato</StyledTableCellHeader>
            <StyledTableCellHeader>Fejlbesked</StyledTableCellHeader>
            <StyledTableCellHeader>Transaktionskode</StyledTableCellHeader>
            <StyledTableCellHeader>SSID</StyledTableCellHeader>
            <StyledTableCellHeader>Håndteret</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {errorLinesQuery.isFetching && <TableSpinner />}
          {errorLinesQuery.data &&
            errorLinesQuery.data.map((errorLine: ErrorFileLineDto) => (
              <CustomTableRow
                rowColor={errorLine.handled ? "grey" : "yellow"}
                key={errorLine.id}
                cells={[
                  { value: format(new Date(errorLine.createdAtUtc), "dd-MM-yyyy") },
                  { value: errorLine.errorMessage },
                  { value: errorLine.transactionCode },
                  { value: errorLine.serviceSubscriptionId },
                ]}
                actionsCell={
                  <ErrorFileLineHandledToggle
                    id={errorLine.id}
                    handled={errorLine.handled}
                    onChange={(handled) => onHandledToggleHandler(errorLine, handled)}
                  />
                }
                collapsedChildren={
                  <TableRowDetails
                    items={[
                      ["Id", errorLine.id],
                      ["Nr", errorLine.recordNumber.toString()],
                      ["Totalbeløb og nominelkode", errorLine.totalAmountAndNominalCode],
                      ["Fejlbesked", errorLine.errorMessage],
                      ["Beskrivelse", errorLine.description],
                    ]}
                  />
                }
              />
            ))}
        </TableBody>
      </StyledTable>
    </Container>
  )
}

export default ErrorFileLineTable

const Container = styled("div")`
  width: 100%;
`
const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0;
`
const StyledTableCell = styled(TableCell)`
  border: none;
  padding: 0;
`

const StyledTableCellHeader = styled(StyledTableCell)`
  padding: ${(props) => props.theme.spacing(1, 1)};
`
