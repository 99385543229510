import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { da } from "date-fns/locale"
import { SnackbarProvider } from "notistack"
import theme from "src/styles/theme"

interface Props {
  children: React.ReactNode
}
const AppWrapper = (props: Props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 60000, // data will be cached for 1 minute when using the same query key
      },
      mutations: {
        retry: 0,
      },
    },
  })

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
      <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  )
}
export default AppWrapper
