import { styled, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import React from "react"

interface Props {
  value?: string
  label?: string
  name: string
  required?: boolean
  disabled?: boolean
  invalidText?: string
  onChangeHandler: (value?: string) => void
  onIsValidChangeHandler?: (isValid: boolean) => void
  triggerRequiredError?: boolean
}

const CustomInputDate = ({ onIsValidChangeHandler, ...props }: Props) => {
  const isValid = !props.required || !!props.value
  const displayError = !isValid && (!!props.value || props.triggerRequiredError)

  React.useEffect(() => {
    onIsValidChangeHandler && onIsValidChangeHandler(isValid)
  }, [isValid, onIsValidChangeHandler])

  return (
    <Container>
      <DatePicker
        disabled={props.disabled}
        label={props.label}
        value={props.value || null}
        onChange={(val) => props.onChangeHandler(val ? new Date(val).toISOString() : undefined)}
        inputFormat="dd-MM-yyyy"
        renderInput={(params) => (
          <StyledTextField
            {...params}
            required={props.required}
            error={displayError}
            data-testid={`${props.name}-input`}
            helperText={displayError ? props.invalidText ?? " " : " "}
          />
        )}
      />
    </Container>
  )
}
export default CustomInputDate

const StyledTextField = styled(TextField)`
  width: 100%;
`

const Container = styled("div")`
  width: 100%;
`
