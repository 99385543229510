import { styled } from "@mui/material"
import { ReactComponent as LogoSvg } from "../../images/logo.svg"
import LogoutButton from "../Buttons/LogoutButton"

interface Props {
  isLoggedIn: boolean
}

const Header = (props: Props) => {
  return (
    <HeaderContainer>
      <Logo data-testid="logo" />
      <Title>Chargefile</Title>
      <LogoutContainer>{props.isLoggedIn && <LogoutButton />}</LogoutContainer>
    </HeaderContainer>
  )
}
export default Header

const HeaderContainer = styled("header")`
  width: 100%;
  transition: background-color 350ms ease 0s;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3, 0)};
  border-bottom: 2px dashed ${(props) => props.theme.palette.divider};
`

const Logo = styled(LogoSvg)`
  width: 80px;
  fill: ${(props) => props.theme.palette.secondary.main};
  margin-right: ${(props) => props.theme.spacing(2)};
`

const Title = styled("span")`
  font-size: 20px;
`

const LogoutContainer = styled("div")`
  margin-left: auto;
`
