/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Unit {
    UNKNOWN = 'Unknown',
    DKK = 'Dkk',
    DKK_PER_METER = 'DkkPerMeter',
    DKK_PER_HOUR = 'DkkPerHour',
}
