import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";

export const getOrderInformation = async (ssid: string) => {
  try {
    var result = await createTeleTechnicianWorkApiClient().works.getApiWorksOrderInformation(ssid);
    return result;
  }
  catch (err) {
    console.log(err);
    throw new Error("Ugyldigt ssid");
  }
};