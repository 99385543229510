import { CircularProgress, styled, Typography } from "@mui/material"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import React from "react"
import { useAuthorizationRedirectFlow } from "./auth/useAuthorizationRedirectFlow"
import DeploymentInfo from "./components/DeploymentInfo"
import Header from "./components/Header/Header"
import MainContent from "./components/MainContent/MainContent"
function App() {
  const { isAuthorized, error, isLoading } = useAuthorizationRedirectFlow()

  return (
    <React.StrictMode>
      <Container>
        <ContentWrapper>
          <Header isLoggedIn={isAuthorized} />
          {isAuthorized ? (
            <MainContent />
          ) : isLoading ? (
            <CircularProgress title="Signing in.." />
          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
            <Typography>
              Du er ikke logget ind! Du vil blive videresendt til Norlys AD inden for et par
              sekunder.
            </Typography>
          )}
        </ContentWrapper>

        {isAuthorized && (
          <DeploymentInfoContainer>
            <DeploymentInfo />
          </DeploymentInfoContainer>
        )}
      </Container>
      {/* This will only be active on DEV by default */}
      <ReactQueryDevtools initialIsOpen={false} />
    </React.StrictMode>
  )
}

export default App

const Container = styled("div")`
  width: 100%;
  position: relative;
`

const ContentWrapper = styled("div")`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const DeploymentInfoContainer = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: ${({ theme }) => theme.spacing(2)};
  width: 350px;
`
