/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErrorFileLineDto } from '../models/ErrorFileLineDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ErrorFileLinesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Mark error file line as handled
     * @param id 
     * @param handled 
     * @returns void 
     * @throws ApiError
     */
    public putApiErrorFileLinesToggleHandled(
id: string,
handled?: boolean,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ErrorFileLines/{id}/ToggleHandled',
            path: {
                'id': id,
            },
            query: {
                'handled': handled,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get ErrorFileLines by date
     * @param fromDate 
     * @param toDate 
     * @returns ErrorFileLineDto Success
     * @throws ApiError
     */
    public getApiErrorFileLines(
fromDate?: string,
toDate?: string,
): CancelablePromise<Array<ErrorFileLineDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ErrorFileLines',
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
