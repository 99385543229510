import { styled, TextField } from "@mui/material"
import React from "react"

interface Props {
  value?: string
  label?: string
  name: string
  regex?: RegExp
  minValue?: number
  invalidText?: string
  disabled?: boolean
  required?: boolean
  triggerRequiredError?: boolean
  type?: "text" | "number"
  onChangeHandler?: (value?: string) => void
  onIsValidChangeHandler?: (isValid: boolean) => void
}

const CustomInputField = ({ onIsValidChangeHandler, ...props }: Props) => {
  const isValid =
    (!props.required || !!props.value) &&
    (!props.regex || props.regex?.test(props.value ?? "") === true) &&
    (!props.minValue || Number(props.value ?? 0) >= props.minValue)
  const displayError = !isValid && (!!props.value || props.triggerRequiredError)

  React.useEffect(() => {
    onIsValidChangeHandler && onIsValidChangeHandler(isValid)
  }, [isValid, onIsValidChangeHandler])

  return (
    <Container>
      <StyledTextField
        type={props.type ?? "text"}
        data-testid={`${props.name}-input`}
        helperText={displayError ? props.invalidText ?? " " : " "}
        required={props.required}
        label={props.label}
        InputLabelProps={{
          // this is necessary to force the label to shrink when a field is automatically filled.
          // for some reason it doesnt work by itself when you dont manually type in the field.
          shrink: props.value !== undefined && props.value?.length > 0 ? true : false,
        }}
        disabled={props.disabled}
        value={props.value ?? ""}
        onChange={(event) => props.onChangeHandler && props.onChangeHandler(event.target.value)}
        error={displayError}
      />
    </Container>
  )
}
export default CustomInputField

const StyledTextField = styled(TextField)`
  width: 100%;
`

const Container = styled("div")`
  width: 100%;
`
