import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";

export const getProducts = async () => {
  try {
    var result = await createTeleTechnicianWorkApiClient().products.getApiProductsDefault();
    return result;
  }
  catch (err) {
    console.log(err);
    throw new Error("Kunne ikke indlæse produkter");
  }
};