import { FormControl, FormHelperText, InputLabel, MenuItem, Select, styled } from "@mui/material"
import React from "react"
import { PriceDto, ProductDto } from "src/api/generated"

interface Props {
  product?: ProductDto
  value?: PriceDto
  disabled?: boolean
  onChangeHandler: (value?: PriceDto) => void
  onIsValidChangeHandler?: (isValid: boolean) => void
  triggerRequiredError?: boolean
}

const ProductPriceSelector = ({
  onIsValidChangeHandler: _onIsValidChangeHandler,
  onChangeHandler: _onChangeHandler,
  ...props
}: Props) => {
  const prices = React.useMemo(() => {
    return props.product?.prices || []
  }, [props.product])

  const isDisabled = !props.product
  const currentValue = !!props.value ? props.value.id : prices.length > 0 ? prices[0].id : ""
  const isValid = !!currentValue
  const displayError = !isValid && props.triggerRequiredError

  const getMatchingPrice = React.useCallback(
    (value: string) => prices.find((price) => price.id === value),
    [prices]
  )

  // we trigger onChange manually because the Select component doesn't trigger onChange by itself when using a default value
  React.useEffect(() => {
    if (!props.value && !!currentValue) _onChangeHandler(getMatchingPrice(currentValue))
  }, [_onChangeHandler, currentValue, getMatchingPrice, props.value])

  return (
    <Container>
      <FormControl
        fullWidth
        disabled={props.disabled === true || isDisabled}
        error={displayError}
        required
      >
        <InputLabel id="product-price-selector-label">Pris</InputLabel>
        <Select
          labelId="product-price-selector-label"
          data-testid="product-price-selector"
          value={currentValue}
          label="Produkt"
          onChange={(event) => _onChangeHandler(getMatchingPrice(event.target.value))}
        >
          {prices.map((price) => (
            <MenuItem key={price.id} value={price.id}>
              {`${price.value} (${price.unit.toString()})`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{displayError ? "Pris påkrævet" : " "}</FormHelperText>
      </FormControl>
    </Container>
  )
}
export default ProductPriceSelector

const Container = styled("div")`
  width: 100%;
`
