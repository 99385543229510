import { CircularProgress, styled, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getAssemblyInfo } from "src/api/queries/getAssemblyInfo"

const DeploymentInfo = () => {
  const assemblyInfoQuery = useQuery(["getAssemblyInfo"], getAssemblyInfo)

  const frontendVersion = process.env.REACT_APP_VERSION
  const frontendName = "TeleTechnicianWorkFrontend"

  const concatednatedList = [...(assemblyInfoQuery.data || [])]
  if (frontendVersion && frontendName) {
    concatednatedList.push({ name: frontendName, version: frontendVersion })
  }

  return (
    <Container>
      {assemblyInfoQuery.isLoading ? (
        <CircularProgress color="primary" size={30} />
      ) : (
        <>
          {concatednatedList.map((assemblyInfo) => (
            <AssemblyInfoBox key={assemblyInfo.name}>
              <Name>{assemblyInfo.name}</Name>
              <Typography>{assemblyInfo.version}</Typography>
            </AssemblyInfoBox>
          ))}
        </>
      )}
    </Container>
  )
}
export default DeploymentInfo

const Container = styled("div")`
  width: 100%;
  text-align: center;
`

const AssemblyInfoBox = styled("div")`
  display: flex;
  justify-content: space-between;
`

const Name = styled(Typography)`
  font-weight: bold;
`
