import { Button } from "@mui/material"

export const handleLogout = () => {
  localStorage.clear()
  window.location.reload()
}

const LogoutButton = () => {
  return (
    <Button onClick={handleLogout} color="error">
      Log ud
    </Button>
  )
}
export default LogoutButton
