import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";

export const getWork = async (isHandled: boolean, searchQuery: string, fromDate?: string, toDate?: string) => {
  if (!!fromDate) {
    // set fromDate to the beginnin of the day
    var tmpFromDate = new Date(fromDate).setHours(0, 0, 0, 0);
    fromDate = new Date(tmpFromDate).toISOString();
  }

  if (!!toDate) {
    // set toDate to the end of the day
    var tmpToDate = new Date(toDate).setHours(23, 59, 59, 999);
    toDate = new Date(tmpToDate).toISOString();
  }

  try {
    var result = await createTeleTechnicianWorkApiClient().works.getApiWorks(isHandled, searchQuery, fromDate, toDate);
    return result;
  }
  catch (err) {
    console.log(err);
    throw new Error("Kunne ikke indlæse work");
  }
};