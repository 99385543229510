import { Container, Grid, styled, Typography } from "@mui/material"

interface Props {
  /**
   * Title, text
   */
  items: [string, string][]
}

const TableRowDetails = ({ items }: Props) => {
  return (
    <Container>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 4 }}>
        {items.map(([title, text]) => (
          <Grid key={title} item xs={4}>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
export default TableRowDetails

const Title = styled(Typography)`
  font-weight: bold;
`
const Text = styled(Typography)`
  word-break: break-word;
`
