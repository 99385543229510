import { Checkbox } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { toggleErrorFileLinesHandled } from "src/api/mutations/toggleErrorFileLinesHandled"
import { useCustomSnack } from "src/hooks/useCustomSnack"

interface Props {
  id: string
  handled: boolean
  onChange: (handled: boolean) => void
}

const ErrorFileLineHandledToggle = (props: Props) => {
  const { showSnack } = useCustomSnack()

  const toggleErrorFileLinesHandledMuration = useMutation(toggleErrorFileLinesHandled, {
    onError: (error) => {
      showSnack((error as Error).message, "error")
    },
  })

  return (
    <Checkbox
      style={{ padding: 0 }}
      checked={props.handled}
      data-testid="error-file-line-handled-toggle"
      onChange={(event) => {
        toggleErrorFileLinesHandledMuration.mutate({ id: props.id, handled: event.target.checked })
        props.onChange(event.target.checked)
      }}
    />
  )
}

export default ErrorFileLineHandledToggle
