import { CircularProgress, styled, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import React from "react"
import { SiTeleConnectionOrderInformationDto } from "src/api/generated"
import { getOrderInformation } from "src/api/queries/getOrderInformation"

interface Props {
  ssid?: string
  io?: string
  triggerRequiredError?: boolean
  onSsidChangeHandler: (value?: string) => void
  onIOChangeHandler: (value?: string) => void
  disabled?: boolean
}

const SsidInput = (props: Props) => {
  const queryClient = useQueryClient()
  const queryKey = "getOrderInformation"

  const orderInformationQuery = useQuery<SiTeleConnectionOrderInformationDto, Error>(
    [queryKey],
    () => getOrderInformation(props.ssid!),
    {
      enabled: false,
      onSuccess(data) {
        props.onIOChangeHandler(data.fiberCompany)
      },
      onError(err) {
        props.onIOChangeHandler(undefined)
      },
    }
  )

  const isValid = !!props.io
  const displayError =
    !orderInformationQuery.isFetching &&
    ((props.triggerRequiredError && !isValid) || orderInformationQuery.isError)

  const orderInformationQueryTimeoutRef = React.useRef<NodeJS.Timeout | undefined>()

  const setSsid = (value: string) => {
    queryClient.cancelQueries([queryKey]) // cancel any previous query
    orderInformationQuery.remove() // clear any cached data
    clearTimeout(orderInformationQueryTimeoutRef.current) // clear any existing timeouts

    props.onSsidChangeHandler(value)
    props.onIOChangeHandler(undefined)

    if (!value) return

    orderInformationQueryTimeoutRef.current = setTimeout(() => {
      orderInformationQuery.refetch({ cancelRefetch: true })
    }, 300)
  }

  return (
    <Container>
      <StyledTextField
        data-testid="ssid-input"
        type={"text"}
        helperText={orderInformationQuery.error?.message ?? (displayError ? "SSID påkrævet" : " ")}
        required={true}
        label={"SSID"}
        InputLabelProps={{
          // this is necessary to force the label to shrink when a field is automatically filled.
          // for some reason it doesnt work by itself when you dont manually type in the field.
          shrink: props.ssid !== undefined && props.ssid?.length > 0 ? true : false,
        }}
        disabled={props.disabled === true}
        value={props.ssid ?? ""}
        onChange={(event) => setSsid(event.target.value)}
        error={displayError}
      />

      {orderInformationQuery.isFetching && (
        <StatusContainer>
          <CircularProgress color="primary" size={16} />
        </StatusContainer>
      )}
      {!!props.io && !!orderInformationQuery.data?.fiberCompany && (
        <StatusContainer>
          <StyledIo data-testid="ssid-success-text">
            {orderInformationQuery.data.fiberCompany}
          </StyledIo>
        </StatusContainer>
      )}
    </Container>
  )
}
export default SsidInput

const StyledTextField = styled(TextField)`
  width: 100%;
`

const Container = styled("div")`
  width: 100%;
  position: relative;
`

const StatusContainer = styled("div")`
  position: absolute;
  top: ${(props) => props.theme.spacing(0.5)};
  right: ${(props) => props.theme.spacing(0.5)};
  display: flex;
`

const StyledIo = styled(Typography)`
  color: ${(props) => props.theme.palette.success.main};
  font-size: 0.75rem;
`
