import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";

export const getAssemblyInfo = async () => {
  try {
    var result = await createTeleTechnicianWorkApiClient().assemblyInfo.getApiAssemblyInfo();
    return result;
  }
  catch (err) {
    console.log(err);
    throw new Error("Kunne ikke indlæse assembly information");
  }
};