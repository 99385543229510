/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseWorkDto } from '../models/ResponseWorkDto';
import type { SiTeleConnectionOrderInformationDto } from '../models/SiTeleConnectionOrderInformationDto';
import type { WorkDto } from '../models/WorkDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add Work
     * @param requestBody 
     * @returns ResponseWorkDto Created
     * @throws ApiError
     */
    public postApiWorks(
requestBody?: WorkDto,
): CancelablePromise<ResponseWorkDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Works',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param isHandled 
     * @param searchQuery 
     * @param fromDate 
     * @param toDate 
     * @returns ResponseWorkDto Success
     * @throws ApiError
     */
    public getApiWorks(
isHandled?: boolean,
searchQuery?: string,
fromDate?: string,
toDate?: string,
): CancelablePromise<Array<ResponseWorkDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Works',
            query: {
                'isHandled': isHandled,
                'searchQuery': searchQuery,
                'fromDate': fromDate,
                'toDate': toDate,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * Update Work
     * @param id 
     * @param requestBody 
     * @returns ResponseWorkDto Success
     * @throws ApiError
     */
    public putApiWorks(
id: string,
requestBody?: WorkDto,
): CancelablePromise<ResponseWorkDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Works/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete Work
     * Must be unhandled to allow deletion
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteApiWorks(
id: string,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Works/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get Order Information on ServiceSubscriptionId
     * @param serviceSubscriptionId 
     * @returns SiTeleConnectionOrderInformationDto Success
     * @throws ApiError
     */
    public getApiWorksOrderInformation(
serviceSubscriptionId: string,
): CancelablePromise<SiTeleConnectionOrderInformationDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Works/OrderInformation/{serviceSubscriptionId}',
            path: {
                'serviceSubscriptionId': serviceSubscriptionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
