/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import jwt_decode from "jwt-decode";
import React from "react";
import { handleLogout } from "src/components/Buttons/LogoutButton";
import { generateCodeChallenge } from "./generateCodeChallenge";

const clientId = process.env.REACT_APP_AZURE_AD_CLIENT_ID!;
const tenantId = process.env.REACT_APP_AZURE_TENANT_ID!;
const authority = process.env.REACT_APP_AZURE_AUTHORITY!.replace("tenantId", tenantId);
const redirectUrl = process.env.REACT_APP_AZURE_REDIRECT_URL!;
const scope = `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/openid openid profile offline_access`;
const azureSignInUrl = `${authority}/oauth2/v2.0/authorize?client_id=${clientId}&prompt=select_account&response_type=code&redirect_uri=${redirectUrl}&response_mode=query&scope=${scope}&code_challenge={codeChallenge}&code_challenge_method=S256`;
const azureTokenUrl = `${authority}/oauth2/v2.0/token`;
const authorizationCode = new URLSearchParams(window.location.search).get("code");
// https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow#error-response
const returnedError = new URLSearchParams(window.location.search).get("error");
const returnedErrorDescription = new URLSearchParams(window.location.search).get("error_description");

export const useAuthorizationRedirectFlow = () => {
  const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);
  const [loadingAccessToken, setIsLoadingAccessToken] = React.useState<boolean>(false);
  const [refreshToken, setRefreshToken] = React.useState<string | null>(localStorage.getItem("refreshToken"));
  const [accessToken, setAccessToken] = React.useState<string | null>(localStorage.getItem("accessToken"));
  const [error, setError] = React.useState<string | null>(null);

  // Redirecting to the azure oauth server if not authenticated
  if (authorizationCode === null && accessToken === null) {
    console.log("redirect triggered");
    generateCodeChallenge().then(({ codeVerifier, codeChallenge }) => {
      // set local storage code verifier
      localStorage.setItem("codeChallengeVerifier", codeVerifier);
      // consider setting "state" query param at some point.
      window.location.href = azureSignInUrl.replace("{codeChallenge}", codeChallenge);
    });
  }

  // refresh access tokens.
  // happens on load if access token is expired, and a timer is set to refresh before a valid access token runs out.
  // the tokens will be swapped in local storage
  React.useEffect(() => {
    const accessTokenExpiry = accessToken ? jwt_decode<any>(accessToken).exp * 1000 : null;
    if (accessTokenExpiry == null)
      return;

    if (accessTokenExpiry < Date.now() - 300000) {
      console.log("refreshing token (access token had expired)");
      getTokens(true);
      return;
    }
    else {
      setIsAuthorized(true);
    }

    console.log("token set to refresh in " + (accessTokenExpiry - Date.now() - 300000) / 1000 + " seconds");
    const timeout = setTimeout(() => {
      console.log("token refreshed (5 minutes before access token expiration)");
      getTokens(true);
    }, accessTokenExpiry - Date.now() - 300000);

    return () => clearTimeout(timeout);
  }, [accessToken])

  // this section triggers upon load and checks if we have just been redirected from Azure AD.
  // It will extract the code from the URL and exchange it for an access token.
  React.useEffect(() => {
    if (returnedError) {
      setError("Failed to sign in. Try again or contact support.");
      console.log(`${returnedError} - ${returnedErrorDescription}`);
      return;
    }

    // if "code" exists in the url, we're being redirected back from Azure AD
    if (!authorizationCode)
      return;

    setIsLoadingAccessToken(true);

    console.log("authorization code received. fetching access token");
    var ls_codeVerifier = localStorage.getItem("codeChallengeVerifier");
    if (ls_codeVerifier) {
      getTokens(false, ls_codeVerifier).then(() => {
        // remove all query params from url to clean up
        window.history.pushState("", document.title, window.location.pathname);
        localStorage.removeItem("codeChallengeVerifier");
      })
    }
  }, []);

  const getTokens = (refresh = false, ls_codeVerifier?: string) => {
    return new Promise((resolve, reject) => {
      const params: any = {
        grant_type: refresh ? "refresh_token" : "authorization_code",
        redirect_uri: redirectUrl,
        client_id: clientId,
        scope: scope,
      };

      if (refresh === false) {
        params.code = authorizationCode;
        params.code_verifier = ls_codeVerifier;
      }
      else {
        params.refresh_token = refreshToken
      }

      const data = (Object.keys(params) as (keyof typeof params)[]).map((key) => `${(key as string)}=${encodeURIComponent(params[key])}`).join("&");
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data,
        url: azureTokenUrl,
      };

      axios(options).then((response) => {
        localStorage.setItem("accessToken", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        setIsAuthorized(true);
        setIsLoadingAccessToken(false);

        resolve(true);
      })
        .catch((error) => {
          // when the refresh token is expired which happens after a day or so. Force a re-login	
          if (error.response.data.error_codes.includes(700084) || error.response.data.error_codes.includes(700082)) {
            handleLogout()
            return;
          }
          // invalid_grant. AADSTS70000: Provided grant is invalid or malformed.	
          // this error appears to happen when a token from another environment is used. ie. dev token in prod.	
          if (error.response.data.error_codes.includes(70000)) {
            handleLogout()
            return;
          }

          // we should never reach this point
          setError("Failed to sign in. Try again or contact support.");
          setIsAuthorized(false);
          setIsLoadingAccessToken(false);
          reject();
        });
    })
  };

  return { isAuthorized, error, isLoading: loadingAccessToken };
}