import { createTeleTechnicianWorkApiClient } from "../ApiClientFactory";
import { WorkDto } from "../generated";
import { genericErrorMessage } from "../serviceNowLink";

export const createWork = async (obj: WorkDto) => {
  try {
    var result = await createTeleTechnicianWorkApiClient().works.postApiWorks(
      {
        serviceSubscriptionId: obj.serviceSubscriptionId,
        productId: obj.productId,
        dateOfDelivery: obj.dateOfDelivery,
        totalPrice: obj.totalPrice,
        priceVolume: obj.priceVolume,
        description: obj.description
      }
    );

    return {
      serviceSubscriptionId: result.serviceSubscriptionId,
      productId: result.productId,
      dateOfDelivery: result.dateOfDelivery,
      totalPrice: result.totalPrice,
      priceVolume: result.priceVolume,
      description: result.description
    } as WorkDto;
  } catch (err) {
    throw new Error(`Linjen kunne ikke oprettes. ${genericErrorMessage}`);
  }
};