import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { IconButton, styled, TableCell, TableRow } from "@mui/material"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import React from "react"

export type RowColor = "grey" | "yellow" | "white"
interface Props {
  cells: { value: string; alignRight?: boolean }[]
  collapsedChildren?: React.ReactNode
  rowColor: RowColor
  actionsCell?: React.ReactNode
}

const CustomTableRow = (props: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow data-testid="table-row">
        <StyledTableCell $disablePaddingBottom={open}>
          <StyledCellContent $rowColor={props.rowColor} $open={open} $firstTd={true}>
            <IconButton data-testid="expand-row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledCellContent>
        </StyledTableCell>
        {props.cells.map((cell, index) => (
          <StyledTableCell key={index} $disablePaddingBottom={open}>
            <StyledCellContent
              $rowColor={props.rowColor}
              $open={open}
              $lastTd={index === props.cells.length - 1 && !props.actionsCell}
              $alignRight={cell.alignRight}
            >
              <CellText title={cell.value}>{cell.value}</CellText>
            </StyledCellContent>
          </StyledTableCell>
        ))}
        {props.actionsCell && (
          <StyledTableCell $disablePaddingBottom={open}>
            <StyledCellContent $alignRight $rowColor={props.rowColor} $open={open} $lastTd={true}>
              {props.actionsCell}
            </StyledCellContent>
          </StyledTableCell>
        )}
      </TableRow>
      {props.collapsedChildren && (
        <StyledCollapseableTableRow $open={open} $rowColor={props.rowColor}>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={props.cells.length + 1 + (props.actionsCell ? 1 : 0)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <StyledBox data-testid="table-row-details">{props.collapsedChildren}</StyledBox>
            </Collapse>
          </StyledTableCell>
        </StyledCollapseableTableRow>
      )}
    </>
  )
}

export default CustomTableRow

const StyledCollapseableTableRow = styled(TableRow)<{ $open: boolean; $rowColor: RowColor }>`
  td {
    box-sizing: border-box;
    border-bottom-style: solid;
    &:first-child {
      border-left-style: solid;
    }
    &:last-child {
      border-right-style: solid;
    }

    border-color: ${(props) =>
      props.$rowColor === "grey"
        ? props.theme.palette.grey[200]
        : props.$rowColor === "yellow"
        ? props.theme.palette.warning.main
        : props.theme.palette.common.black};

    // the "white" theme differs from the rest due to the fact that it uses a border.
    border-width: ${(props) =>
      props.$open && props.$rowColor === "white" ? "1px" : props.$open ? "3px" : "0px"};
  }
`

const StyledTableCell = styled(TableCell)<{ $disablePaddingBottom?: boolean }>`
  padding-top: ${(props) => props.theme.spacing(0.5)};
  padding-bottom: ${(props) => (!!props.$disablePaddingBottom ? "0px" : props.theme.spacing(0.5))};
  padding-left: 0;
  padding-right: 0;
  border: none;
  max-width: 250px;
`

const StyledCellContent = styled("div")<{
  $open: boolean
  $rowColor: RowColor
  $firstTd?: boolean
  $lastTd?: boolean
  $alignRight?: boolean
}>`
  padding: ${(props) => props.theme.spacing(0, 1)};
  padding-right: ${(props) =>
    props.$alignRight ? props.theme.spacing(3) : props.theme.spacing(1)};
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$alignRight ? "flex-end" : "flex-start")};
  background-color: ${(props) =>
    props.$rowColor === "grey"
      ? props.theme.palette.grey[200]
      : props.$rowColor === "yellow"
      ? props.theme.palette.warning.main
      : props.theme.palette.background.paper};

  border-bottom: ${(props) =>
    props.$rowColor === "white" ? `1px solid ${props.theme.palette.common.black}` : "none"};
  border-top: ${(props) =>
    props.$rowColor === "white" ? `1px solid ${props.theme.palette.common.black}` : "none"};

  border-left: ${(props) =>
    props.$rowColor === "white" && !!props.$firstTd
      ? `1px solid ${props.theme.palette.common.black}`
      : "none"};

  border-right: ${(props) =>
    props.$rowColor === "white" && !!props.$lastTd
      ? `1px solid ${props.theme.palette.common.black}`
      : "none"};
`

const CellText = styled("span")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
`

const StyledBox = styled(Box)`
  padding: ${(props) => props.theme.spacing(4, 0)};
`
