import { styled } from "@mui/material"
import React from "react"
import SearchBar from "../SearchBar/SearchBar"
import ErrorFileLineTable from "../Tables/ErrorFileLineTable"
import WorkTable from "../Tables/WorkTable"
import WorkForm from "../WorkForm/WorkForm"

function MainContent() {
  const [searchQuery, setSearchQuery] = React.useState("")

  return (
    <Container>
      <TableContainer data-testid="unhandled-data">
        <SearchBar onChangeHandler={(value) => setSearchQuery(value)} />

        <WorkTable
          isHandled={false}
          searchQuery={searchQuery}
          rowColor={"white"}
          title="Åbne / Ikke-sendte"
          editable
        />
        <br />

        <WorkFormContainer data-testid="create-work-form">
          <WorkForm mode="create" />
        </WorkFormContainer>
      </TableContainer>

      <TableContainer>
        <ErrorFileLineTable />
      </TableContainer>

      <TableContainer data-testid="historical-data">
        <WorkTable
          isHandled={true}
          searchQuery={searchQuery}
          rowColor={"grey"}
          title="Historisk data"
          editable={false}
        />
      </TableContainer>
    </Container>
  )
}

export default MainContent

const Container = styled("div")`
  box-sizing: border-box;
  width: 100%;
  padding: ${(props) => props.theme.spacing(3, 0)};
  margin: 0 auto;
`

const TableContainer = styled("div")`
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing(6)};
  border-bottom: 1px solid ${(props) => props.theme.palette.common.black};
  margin-bottom: ${(props) => props.theme.spacing(10)};
`

const WorkFormContainer = styled("div")`
  border: 1px solid ${(props) => props.theme.palette.common.black};
  padding: ${(props) => props.theme.spacing(4, 4)};
  margin-bottom: ${(props) => props.theme.spacing(3)};
`
