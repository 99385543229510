/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AssemblyInfoService } from './services/AssemblyInfoService';
import { ErrorFileLinesService } from './services/ErrorFileLinesService';
import { HealthService } from './services/HealthService';
import { ProductsService } from './services/ProductsService';
import { WorksService } from './services/WorksService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class TeleTechnicianWorkApiClient {

    public readonly assemblyInfo: AssemblyInfoService;
    public readonly errorFileLines: ErrorFileLinesService;
    public readonly health: HealthService;
    public readonly products: ProductsService;
    public readonly works: WorksService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.assemblyInfo = new AssemblyInfoService(this.request);
        this.errorFileLines = new ErrorFileLinesService(this.request);
        this.health = new HealthService(this.request);
        this.products = new ProductsService(this.request);
        this.works = new WorksService(this.request);
    }
}
